import { AccessController } from '@/components/common/AccessController';
import { AccessEnum } from '@/app/auth/roles';
import { CatalogTypes } from '@/components/sections/CatalogsBrowse/constants';
import { NavDropdownItem } from '@/components/widgets/Navbar/NavDropdownItem';
import { CustomLink, LinkType } from '../CustomLink';
import { useNavbar } from '../store';
import { NavbarLinksWrapper } from './NavbarLinks.styled';
import { useHackedDataGridPro } from '@/hooks/useHackedDataGridPro';
const URL = 'https://yoda.sec2.ru/open?screen=report$Report.run';

export const NavbarLinks = () => {
  useHackedDataGridPro();
  const { toggleDrawer } = useNavbar();
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if ((event.target as HTMLElement).closest('a')) {
      toggleDrawer(false); // Закрываем меню
    }
  };

  return (
    <>
      <NavbarLinksWrapper onClick={handleClick}>
        <AccessController access={[AccessEnum.ShowReports]}>
          <CustomLink
            to={URL}
            type={LinkType.OutMainLink}
          >
            Отчеты
          </CustomLink>
        </AccessController>

        <AccessController access={[AccessEnum.MapUspd, AccessEnum.MapUspdOnly]}>
          <NavDropdownItem
            type={'text'}
            parentTitle={'ПНР'}
          >
            <AccessController access={[AccessEnum.MapUspd, AccessEnum.MapUspdOnly]}>
              <CustomLink
                to={'/map/network'}
                type={LinkType.ItemLink}
                // onClick={() => toggleDrawer(false)}
              >
                Карта УСПД
              </CustomLink>
            </AccessController>
            <AccessController access={[AccessEnum.MapUspd]}>
              <CustomLink
                to={'/task/map'}
                type={LinkType.ItemLink}
              >
                Карта
              </CustomLink>
            </AccessController>
            <AccessController access={[AccessEnum.MapUspd]}>
              <CustomLink
                to={'/pnr-entrance'}
                type={LinkType.ItemLink}
              >
                Дома
              </CustomLink>
            </AccessController>
          </NavDropdownItem>
        </AccessController>

        <AccessController access={[AccessEnum.TasksBrowse]}>
          <CustomLink to={`tasks/browse`}>Заявки</CustomLink>
        </AccessController>

        <AccessController
          access={[
            AccessEnum.TaskAssignMap,
            AccessEnum.CallingMap,
            AccessEnum.CallingMapOperator,
            AccessEnum.PpoMap,
          ]}
        >
          <NavDropdownItem
            type={'text'}
            parentTitle={'Карты'}
          >
            <AccessController access={[AccessEnum.TaskAssignMap]}>
              <CustomLink
                to={`/task/zoned-map`}
                type={LinkType.ItemLink}
              >
                Карта (назначить)
              </CustomLink>
            </AccessController>
            <AccessController access={[AccessEnum.CallingMap, AccessEnum.CallingMapOperator]}>
              <CustomLink
                to={`/task/calling-map`}
                type={LinkType.ItemLink}
              >
                Карта (обзвона)
              </CustomLink>
            </AccessController>
            <AccessController access={[AccessEnum.PpoMap, AccessEnum.CallingMapOperator]}>
              <CustomLink
                to={`/tasks/ppo-map`}
                type={LinkType.ItemLink}
              >
                Карта ППО
              </CustomLink>
            </AccessController>
          </NavDropdownItem>
        </AccessController>
        <AccessController access={[AccessEnum.ComplaintsPage]}>
          <CustomLink to='complaints'>Жалобы</CustomLink>
        </AccessController>
        <AccessController
          access={[AccessEnum.Catalogs, AccessEnum.CatalogsMeter, AccessEnum.MapUspd]}
        >
          <NavDropdownItem
            type={'text'}
            parentTitle={'Справочники'}
          >
            <AccessController access={[AccessEnum.CatalogsMeter]}>
              <CustomLink
                to={`/catalogs/${CatalogTypes.PU}`}
                type={LinkType.ItemLink}
              >
                Приборы учета
              </CustomLink>
            </AccessController>
            <CustomLink
              to={`/catalogs/${CatalogTypes.STAMP}`}
              type={LinkType.ItemLink}
            >
              Пломбы
            </CustomLink>
            <CustomLink
              to={`/catalogs/${CatalogTypes.KOO}`}
              type={LinkType.ItemLink}
            >
              Каналообразующее оборудование
            </CustomLink>
            <CustomLink
              to={`/catalogs/${CatalogTypes.SIM}`}
              type={LinkType.ItemLink}
            >
              SIM карты
            </CustomLink>
            <AccessController access={[AccessEnum.MapUspd]}>
              <CustomLink
                to={`/catalogs/odpu-management-company`}
                type={LinkType.ItemLink}
              >
                Управляющие компании
              </CustomLink>
            </AccessController>
          </NavDropdownItem>
        </AccessController>

        <AccessController access={[AccessEnum.Storehouse, AccessEnum.StorehouseInfo]}>
          <CustomLink to='/storehouse'>Склад</CustomLink>
        </AccessController>

        <AccessController access={[AccessEnum.Registry, AccessEnum.Drive, AccessEnum.PUManaging]}>
          <NavDropdownItem
            type={'text'}
            parentTitle={'Реестры'}
          >
            <AccessController access={[AccessEnum.Registry]}>
              <CustomLink
                to='registry/browse'
                type={LinkType.ItemLink}
              >
                Реестр
              </CustomLink>
            </AccessController>
            <AccessController access={[AccessEnum.Registry]}>
              <CustomLink
                to='registry/enforse'
                type={LinkType.ItemLink}
              >
                Реестр Энфорс
              </CustomLink>
            </AccessController>
            <AccessController access={[AccessEnum.Drive]}>
              <CustomLink
                to='registry/drive'
                type={LinkType.ItemLink}
              >
                ИД ТехПрис
              </CustomLink>
            </AccessController>
            {/*<AccessController access={[AccessEnum.Drive]}>*/}
            <CustomLink
              to='registry/mail-notifications'
              type={LinkType.ItemLink}
            >
              Почтовые уведомления
            </CustomLink>
            <CustomLink
              to='registry/registry-adpu'
              type={LinkType.ItemLink}
            >
              Реестры передачи АДПУ
            </CustomLink>
            <CustomLink
              to='registry/mass-loading-ADPU'
              type={LinkType.ItemLink}
            >
              Массовая прогрузка АДПУ
            </CustomLink>
            {/*</AccessController>*/}

            <AccessController access={[AccessEnum.PUManaging]}>
              <CustomLink
                to='mcRework'
                type={LinkType.ItemLink}
              >
                Замены ПУ на доработку
              </CustomLink>
            </AccessController>
          </NavDropdownItem>
        </AccessController>

        <AccessController access={[AccessEnum.CallCenterIncoming]}>
          <NavDropdownItem
            type={'text'}
            parentTitle={'Call-центр'}
          >
            <AccessController access={[AccessEnum.CallCenterIncoming]}>
              <CustomLink
                to='/callcenter'
                type={LinkType.ItemLink}
              >
                Входящие заявки
              </CustomLink>
            </AccessController>
            <AccessController access={[AccessEnum.CallCenterResolved]}>
              <CustomLink
                to='/callcenterrequests'
                type={LinkType.ItemLink}
              >
                Обработанные заявки
              </CustomLink>
            </AccessController>
            <AccessController access={[AccessEnum.CalLCenterResolvedBashkortostan]}>
              <CustomLink
                to='/callcenterrequestsbashkortostan'
                type={LinkType.ItemLink}
              >
                Башкортостан
              </CustomLink>
            </AccessController>
          </NavDropdownItem>
        </AccessController>

        <AccessController
          access={[
            AccessEnum.Statistic,
            AccessEnum.CallingMapOperator,
            AccessEnum.TeamManagerVoormanAndExecutor,
          ]}
        >
          <CustomLink to='/dashboard'>Статистика</CustomLink>
        </AccessController>
      </NavbarLinksWrapper>
    </>
  );
};
