import { ConfirmationDialog } from '@/components/common/ConfirmationDialog';
import { TextField, Typography, Button } from '@mui/material';
import React, { useState } from 'react';
import {
  SupplyPUConfirmationDelete,
  SupplyPUTableHeader,
  SupplyPUTableHeaderItem,
} from './SupplyPUComponent.styled';
import {
  checkErrors,
  EquipmentState,
  FULL_COMPLETENESS_OPTION,
  generatePuExcelFile,
  getPUsFromErrorMaps,
  osNoYodaTaskErrorMap,
  replaceNonNums,
  TextFieldChangeEvent,
  useStorehouseWorkerAccess,
  useValidReturnBillFields,
} from './utils';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { SupplyPUModalProps } from './SupplyPUVirtualizedTable';
import { SupplyTabType } from '../StampComponent/StampComponent';
import useSupplyStore from '../SupplyStore';
import shallow from 'zustand/shallow';
import { SupplyPUItem, SupplyServiceCatalogOption } from '@/dto/taskmap/Dto';
import { useAllPuOwner } from '@/hooks/useQuery/useAllSupplyCatalogs';
import { useAllPuTypes } from '@/hooks/useQuery/useAllPuTypes';
import { GetTaskByMeter } from '@/services/YodaRestService/YodaRestService';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { playAudioByKey } from '@/utils/heplers';
import { LoadingButton } from '@mui/lab';

export interface SuppluPUModalHeaderProps extends SupplyPUModalProps, SupplyTabType {
  selectedPUDeclaredCount: string;
  setSelectedPUDeclaredCount: React.Dispatch<React.SetStateAction<string>>;
  savePUItems: (forcedPuListItems?: SupplyPUItem[]) => void;
  closeScanning: () => void;
  removeErroredScanRows: (dupIds: number[], existIds: number[]) => void;
  confirmAllRows: () => void;
  viewMode?: boolean;
  setPUListItems: React.Dispatch<React.SetStateAction<SupplyPUItem[]>>;
  noYodaTaskErrorMap: osNoYodaTaskErrorMap;
  setNoYodaTaskErrorMap: (noYodaTaskErrorMap: osNoYodaTaskErrorMap) => void;
  loadingCheckExist: boolean;
}

interface ErrorPuDialog {
  open: boolean;
  dup?: string[];
  exist?: string[];
  noYodaTasks?: string[];
  allIds?: number[];
  removeIds?: number[];
}

const SupplyPUModalHeader = ({
  selectedPUDeclaredCount,
  puListItems,
  osDupFieldError,
  noYodaTaskFieldError,
  osExistsFieldError,
  savePUItems,
  closeScanning,
  removeErroredScanRows,
  setSelectedPUDeclaredCount,
  disabledOrDraftCheck,
  selectedPU,
  returnBill,
  confirmAllRows,
  viewMode,
  tabType,
  billWithConfirm,
  setPUListItems,
  accessProject,
  noYodaTaskErrorMap,
  setNoYodaTaskErrorMap,
  loadingCheckExist,
}: SuppluPUModalHeaderProps) => {
  const isValidReturnBill = useValidReturnBillFields(puListItems, returnBill);
  const workerAccess = useStorehouseWorkerAccess();
  const {
    returnNewItemsMode,
    setReturnNewItemsMode,
    supplyMeterTypes,
    forcedTaskNumberInfo,
    setForcedTaskNumberInfo,
    setFilters,
  } = useSupplyStore(
    (state) => ({
      returnNewItemsMode: state.returnNewItemsMode,
      setReturnNewItemsMode: state.setReturnNewItemsMode,
      supplyMeterTypes: state.supplyMeterTypes,
      forcedTaskNumberInfo: state.forcedTaskNumberInfo,
      setForcedTaskNumberInfo: state.setForcedTaskNumberInfo,
      setFilters: state.setFilters,
    }),
    shallow
  );
  const { puOwnerOptions } = useAllPuOwner();
  const { puTypesResponse } = useAllPuTypes();
  const { fetchCatch, addActionLog } = useActionLog();

  const [removeErrorPuDialog, setRemoveErrorPuDialog] = useState<ErrorPuDialog>({ open: false });
  const [closeAlertOpen, setCloseAlertOpen] = useState(false);
  const [loadAddres, setLoadAddress] = useState(false);

  const puListItemsErrorDisable = checkErrors(
    osDupFieldError,
    osExistsFieldError,
    noYodaTaskFieldError,
    selectedPU?.tmpId
  );
  const factoryTabType = tabType === 'factory_get';
  const disableSaveButton =
    !accessProject &&
    (disabledOrDraftCheck ||
      (puListItemsErrorDisable && !factoryTabType) ||
      !isValidReturnBill ||
      loadingCheckExist);

  const closeRemoveErrorPuDialog = () => {
    setRemoveErrorPuDialog({ ...removeErrorPuDialog, open: false });
  };

  const openRemoveErrorPuDialog = () => {
    const errorPUs = getPUsFromErrorMaps(
      osDupFieldError,
      osExistsFieldError,
      noYodaTaskFieldError,
      puListItems,
      selectedPU?.tmpId
    );

    setRemoveErrorPuDialog({ ...errorPUs, open: true });
  };

  const removeErrorPUs = () => {
    removeErroredScanRows(removeErrorPuDialog.allIds ?? [], removeErrorPuDialog.removeIds ?? []);
    setRemoveErrorPuDialog({ open: false });
  };

  const handleCsvDownload = () => {
    const filteredPUs = puListItems.filter(
      (it) => (it?.mac && it?.mac?.length > 0) || (it?.number && it?.number?.length > 0)
    );
    generatePuExcelFile(filteredPUs, puOwnerOptions, puTypesResponse, selectedPU?.meterName);
  };

  const handleCloseScanning = () => {
    setCloseAlertOpen(false);
    closeScanning();
    setFilters(() => ({}));
  };

  const handleChangeDeclaredCount = (event: TextFieldChangeEvent) => {
    const onlyNums = replaceNonNums(event?.target?.value);
    if (selectedPU) setSelectedPUDeclaredCount(onlyNums ?? '');
  };

  const handleCloseButton = () => {
    viewMode ? handleCloseScanning() : setCloseAlertOpen(true);
  };

  const addedItemsCount = billWithConfirm || viewMode ? puListItems.length : puListItems.length - 1;

  const firstRow = puListItems[0];

  const updateAllRowsAccordingToFirst = () => {
    let stateOption: SupplyServiceCatalogOption | null = null;
    if (
      firstRow?.state?.id === EquipmentState.REPAIRED ||
      firstRow?.state?.id === EquipmentState.NEW ||
      firstRow?.state?.id === EquipmentState.DEFECT
    ) {
      stateOption = firstRow?.state;
    }

    const returnNewItemsPus = puListItems.map((pu) => ({
      ...pu,
      meterTypeId: firstRow.meterTypeId,
      meterTypeName: firstRow.meterTypeName,
      completeness: firstRow?.completeness,
      state: stateOption,
      defectCause: firstRow?.defectCause,
      taskNumber: null,
      completenessComment: firstRow?.completenessComment,
      address: firstRow?.address,
      generalComment: firstRow?.generalComment,
      supplyComment: '-',
      manufactureYear: firstRow?.manufactureYear,
      ownerId: firstRow?.ownerId,
    }));

    return returnNewItemsPus;
  };

  const handleReturnNewItemsClick = () => {
    setReturnNewItemsMode(!returnNewItemsMode);
    setPUListItems(updateAllRowsAccordingToFirst());
  };

  const handleSaveClick = () => {
    setFilters(() => ({}));
    if (returnNewItemsMode) {
      savePUItems(updateAllRowsAccordingToFirst());
    } else {
      savePUItems();
    }
  };

  const getAddressFromYoda = () => {
    const puList = puListItems
      .filter((item) => item.mac || item.number)
      .map((pu) => ({
        mac: pu.mac,
        number: pu.number,
        type: selectedPU?.parentTypeName ?? '',
      }));

    setLoadAddress(true);
    GetTaskByMeter(puList)
      .then(({ data }) => {
        Object.entries(data).forEach(([key, value]) => {
          const pu = puListItems.find((puItem) => puItem.number === key);
          if (!pu) return;

          const puMeterType = supplyMeterTypes.get(pu?.meterTypeId);

          const parentTypeName =
            (puMeterType?.parentId
              ? supplyMeterTypes.get(puMeterType?.parentId)?.name
              : puMeterType?.name) ?? '';

          const forcedTaskKey = `${parentTypeName}-${pu?.mac || pu?.number}`;

          const setNoYodaTaskError = (error: string) => {
            const noTaskList = noYodaTaskErrorMap.get(pu.tmpId) ?? new Map();
            noTaskList.set(pu.tmpId, true);
            noYodaTaskErrorMap.set(pu.tmpId, noTaskList);
            setNoYodaTaskErrorMap(new Map(noYodaTaskErrorMap));

            addActionLog(ActionLogType.ERROR, error);

            const data = forcedTaskNumberInfo.get(forcedTaskKey);
            if (data && typeof data !== 'string') {
              data.error = error;
              forcedTaskNumberInfo.set(forcedTaskKey, data);
            }
          };

          if (value.length === 1) {
            forcedTaskNumberInfo.set(forcedTaskKey, value[0]);

            setForcedTaskNumberInfo(new Map(forcedTaskNumberInfo));

            if (returnNewItemsMode) {
              const firstRowAdress = puListItems[0]?.address;
              if (firstRowAdress !== value[0].originalAddress) {
                const error = `Заявка для ПУ: ${parentTypeName}, ${
                  pu?.mac || pu?.number
                } не соответствует адресу массового возврата: ${firstRowAdress}`;
                setNoYodaTaskError(error);
              }
            } else {
              const noTaskList = noYodaTaskErrorMap.get(pu?.tmpId);
              if (noTaskList) {
                noTaskList.delete(pu?.tmpId);
                noYodaTaskErrorMap.set(pu?.tmpId, noTaskList);
                setNoYodaTaskErrorMap(new Map(noYodaTaskErrorMap));
              }
              return;
            }
          }
        });

        const noTaskList = puListItems.filter((puItem) => puItem.number && !data[puItem.number]);
        const moreOneTask = Object.fromEntries(
          Object.entries(data).filter(([key, value]) => value.length > 1)
        );

        if (noTaskList.length) {
          addActionLog(
            ActionLogType.WARNING,

            `Заявки для ПУ: ${noTaskList
              .map((item) => {
                const puMeterType = supplyMeterTypes.get(item?.meterTypeId);

                const parentTypeName =
                  (puMeterType?.parentId
                    ? supplyMeterTypes.get(puMeterType?.parentId)?.name
                    : puMeterType?.name) ?? '';

                return `${parentTypeName}, ${item.number} `;
              })
              .join('; ')}   не найдены в YODA. Введите номер заявки вручную`
          );
          playAudioByKey('errorAudio');
        }

        if (Object.entries(moreOneTask).length) {
          addActionLog(
            ActionLogType.ERROR,
            `${Object.entries(moreOneTask)
              .map(([key, value]) => {
                const taskItem = puListItems.find((puItem) => puItem.number === key);

                if (!taskItem) return;

                const puMeterType = supplyMeterTypes.get(taskItem?.meterTypeId);

                const parentTypeName =
                  (puMeterType?.parentId
                    ? supplyMeterTypes.get(puMeterType?.parentId)?.name
                    : puMeterType?.name) ?? '';

                return `Для ПУ: ${parentTypeName}, ${key} найдено ${
                  value.length
                } заявки в YODA: ${value.map((item) => item.taskNumber).join(', ')}`;
              })
              .join('; ')} . Обратитесь к администратору`
          );
          playAudioByKey('errorAudio');
        }
      })
      .catch((err) => fetchCatch(err, 'Ошибка во время поиска заявки с соответствующим ПУ'))
      .finally(() => setLoadAddress(false));
  };

  return (
    <>
      <div style={{ marginBottom: 24 }}>
        <SupplyPUTableHeader>
          <SupplyPUTableHeaderItem>
            <TextField
              disabled={accessProject}
              label='Заявленное количество'
              value={selectedPUDeclaredCount}
              onChange={(event) => handleChangeDeclaredCount(event)}
              error={addedItemsCount < parseInt(selectedPUDeclaredCount)}
            />
            <TextField
              disabled={accessProject}
              label='Уже добавлено'
              InputLabelProps={{ shrink: true }}
              contentEditable={false}
              error={addedItemsCount > parseInt(selectedPUDeclaredCount)}
              value={addedItemsCount}
            />
          </SupplyPUTableHeaderItem>
          <SupplyPUTableHeaderItem>
            {returnBill && (
              <Button
                variant='contained'
                color={returnNewItemsMode ? 'success' : undefined}
                onClick={handleReturnNewItemsClick}
                disabled={viewMode}
              >
                Массовый возврат ПУ
              </Button>
            )}
            {billWithConfirm && workerAccess && !viewMode && (
              <Button
                variant='contained'
                onClick={confirmAllRows}
              >
                Подтвердить все
              </Button>
            )}
            <Button
              endIcon={<FileDownloadIcon />}
              variant='contained'
              onClick={handleCsvDownload}
            >
              Скачать файл
            </Button>
            {returnBill && (
              <LoadingButton
                loading={loadAddres}
                variant='contained'
                onClick={getAddressFromYoda}
              >
                Получить адреса из йоды
              </LoadingButton>
            )}
            {puListItemsErrorDisable && !factoryTabType && (
              <Button
                variant='contained'
                color='error'
                onClick={openRemoveErrorPuDialog}
              >
                Удалить ПУ c ошибками
              </Button>
            )}

            <Button
              variant='contained'
              disabled={disableSaveButton}
              onClick={handleSaveClick}
            >
              Сохранить
            </Button>

            <Button
              variant='outlined'
              onClick={handleCloseButton}
            >
              Закрыть
            </Button>
          </SupplyPUTableHeaderItem>
        </SupplyPUTableHeader>
      </div>
      <ConfirmationDialog
        title={`Cистемой были выявлены следующие ошибки`}
        handleOk={removeErrorPUs}
        handleCancel={closeRemoveErrorPuDialog}
        open={removeErrorPuDialog.open}
        textForOk={'Удалить'}
        fullWidth
      >
        <>
          {(!!removeErrorPuDialog?.dup?.length || !!removeErrorPuDialog?.noYodaTasks?.length) && (
            <>
              <Typography variant='subtitle1'>Текущая накладная:</Typography>
              {removeErrorPuDialog?.dup?.map((errorMac, index) => (
                <Typography
                  key={index}
                  variant='subtitle2'
                  dangerouslySetInnerHTML={{ __html: errorMac }}
                />
              ))}
              {removeErrorPuDialog?.noYodaTasks?.map((errorMac, index) => (
                <Typography
                  key={index}
                  variant='subtitle2'
                  dangerouslySetInnerHTML={{ __html: errorMac }}
                />
              ))}
            </>
          )}
          {!!removeErrorPuDialog?.exist?.length && (
            <div style={{ marginTop: 24 }}>
              <Typography variant='subtitle1'>Накладные вкладки &ldquo;Архив&rdquo;:</Typography>
              {removeErrorPuDialog?.exist?.map((errorMac, index) => (
                <Typography
                  key={index}
                  variant='subtitle2'
                  dangerouslySetInnerHTML={{ __html: errorMac }}
                />
              ))}
            </div>
          )}

          <Typography
            variant='body1'
            sx={{ mt: 4 }}
          >
            Вы уверены что хотите <SupplyPUConfirmationDelete>удалить </SupplyPUConfirmationDelete>
            ошибочные ПУ?
          </Typography>
        </>
      </ConfirmationDialog>

      <ConfirmationDialog
        title={`Внимание!`}
        handleOk={handleCloseScanning}
        handleCancel={() => setCloseAlertOpen(false)}
        open={closeAlertOpen}
        textForOk={'Да'}
        textForCancel='Нет'
      >
        <Typography variant='subtitle1'>
          Вы хотите закрыть страницу без сохранения данных?
        </Typography>
      </ConfirmationDialog>
    </>
  );
};

export default SupplyPUModalHeader;
