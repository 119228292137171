import { Route, Routes } from 'react-router-dom';
import { LoginPage } from './page/login/LoginPage';
import RequireAuth from './app/auth/RequireAuth';
import Navbar from './components/widgets/Navbar/NavBar';
import CallCenterPage from './page/callcenter/CallCenterPage';
import DashBoardPage from './page/dashboard/DashBoardPage';
import McReworkPage from './page/mcRework/McReworkPage';
import CallCenterRequestsPage from './page/callcenterrequests/CallCenterRequestsPage';
import StoreHousePage from './page/storehouse/StoreHousePage';
import NetworkMapPage from './page/map/network/NetworkMapPage';
import TaskMapPage from './page/task/map/TaskMapPage';
import { useCatalog } from './hooks/CatalogHook';
import React, { useEffect } from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import { useScreenHoldHook } from './hooks/ScreenHoldHook';
import TasksBrowsePage from './page/tasks/browse/TasksBrowsePage';
import YungaPage from './page/yunga/YungaPage';
import { RoleProvider } from './hooks/useRole';
import TaskEditorPage from './page/task/edit/[taskId]/TaskEditorPage';
import RegistryBrowsePage from './page/registry/browse/RegistryBrowsePage';
import RegistryEditorPage from './page/registry/browse/[id]/RegistryEditorPage';
import PnrEntranceEditPage from './page/pnr-entrance/edit/[houseDtoId]/PnrEntranceEditPage';
import PnrEntrancePage from './page/pnr-entrance/PnrEntrancePage';
import { ZonedMapPage } from './page/task/zoned-map/ZonedMapPage';
import CallCenterBashkortostanPage from './page/callcenterrequestsbashkortostan/CallCenterBashkortostanPage';
import RegistryEnforsePage from './page/registry/enforse/RegistryEnforsePage';
import PpoMapPage from './page/tasks/ppo-map/PpoMapPage';
import AsumbStatisticPage from './page/dashboard/asumb/AsumbStatisticPage';
import RequestStatisticPage from './page/dashboard/requests/RequestStatisticPage';
import CustomerInteractionStatisticsPage from './page/dashboard/interaction/CustomerInteractionStatisticsPage';
import OdpuStatisticPage from './page/dashboard/odpu/OdpuStatisticPage';
import DashboardOutlet from './components/statistics/DashboardOutlet/DashbordOutlet';
import CatalogsPage from './page/catalogs/[catalogType]/CatalogsPage';
import { CatalogTypes } from './components/sections/CatalogsBrowse/constants';
import DrivePage from './page/registry/drive/DrivePage';
import FoldersPage from './page/registry/drive/[resGroupId]/[groupId]/FoldersPage';
import FilesPage from './page/registry/drive/[resGroupId]/[groupId]/[folderId]/FilesPage';
import CallingMapPage from './page/task/calling-map/CallingMapPage';
import DeliveryPointPage from '@/page/deliveryPoint/view/[deliveryPointId]/DeliveryPointPage';
import ResFoldersPage from './page/registry/drive/[resGroupId]/ResFoldersPage';
import MailNotificationsPage from '@/page/registry/mail-notifications/MailNotificationsPage';
import StorehouseAdminPage from './page/storehouse_admin/StorehouseAdminPage';
import CatalogPuEditorPage from './page/catalogs/[catalogType]/[puId]/CatalogPuEditorPage';
import GalleryPage from './page/gallery/GalleryPage';
import CatalogEquipmentEditorPage from './page/catalogs/[catalogType]/[equipmentId]/CatalogEquipmentEditorPage';
import ComplaintsPage from './page/complaints/ComplaintsPage';
import ActionLogsSnackbar from './app/context/SnackbarProvider/ActionLogsSnackbar';
import RolesPage from './page/roles/RolesPage';
import StoreHouseBillItemPage from './page/storehouse/[billId]/StoreHouseBillItemPage';
import MassLoadingADPUPage from '@/page/registry/mass-loading-ADPU/MassLoadingADPUPage';
import RegistryADPUPage from '@/page/registry/registry-adpu/RegistryADPUPage';
import InvestStatisticPage from './page/dashboard/invest/InvestStatisticPage';
import OdpuManagementCompanyCatalogPage from './page/catalogs/[catalogType]/OdpuManagementCompanyCatalogPage';

export const App = () => {
  const { isInProgress } = useScreenHoldHook();

  return (
    <RoleProvider>
      <div>
        <Navbar />
        <ActionLogsSnackbar />
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
          open={isInProgress}
        >
          <CircularProgress color='inherit' />
        </Backdrop>
        <Routes>
          <Route
            path='/login'
            element={<LoginPage />}
          />
          <Route
            path='/'
            element={<RequireAuth />}
          >
            <Route
              path='/map/network'
              element={<NetworkMapPage />}
            />

            <Route
              path='/task/map'
              element={<TaskMapPage />}
            />
            <Route
              path='/task/zoned-map'
              element={<ZonedMapPage />}
            />
            <Route
              path='/task/calling-map'
              element={<CallingMapPage />}
            />
            <Route
              path='/pnr-entrance'
              element={<PnrEntrancePage />}
            />
            <Route
              path='/pnr-entrance/edit/:houseDtoId'
              element={<PnrEntranceEditPage />}
            />

            <Route
              path='/task/edit/:taskId'
              element={<TaskEditorPage />}
            />
            <Route
              path='/delivery-point/view/:deliveryPointId'
              element={<DeliveryPointPage />}
            />
            <Route
              path='/callcenter'
              element={<CallCenterPage />}
            />
            <Route
              path='/callcenter/:taskitemid'
              element={<CallCenterPage />}
            />
            <Route
              path='/callcenterrequests'
              element={<CallCenterRequestsPage />}
            />
            <Route
              path='/callcenterrequestsbashkortostan'
              element={<CallCenterBashkortostanPage />}
            />
            <Route
              path='/storehouse'
              element={<StoreHousePage />}
            />
            <Route
              path='/storehouse/:billId'
              element={<StoreHouseBillItemPage />}
            />
            <Route
              path='/storehouse_admin'
              element={<StorehouseAdminPage />}
            />
            <Route
              path={`tasks/browse`}
              element={<TasksBrowsePage />}
            />
            {/*<Route*/}
            {/*  path={`catalogs/${CatalogTypes.METER}`}*/}
            {/*  element={<CatalogsPage type={CatalogTypes.METER} />}*/}
            {/*/>*/}
            <Route
              path={`catalogs/${CatalogTypes.STAMP}`}
              element={<CatalogsPage type={CatalogTypes.STAMP} />}
            />

            <Route
              path={`catalogs/odpu-management-company`}
              element={<OdpuManagementCompanyCatalogPage />}
            />
            <Route
              path={`catalogs/${CatalogTypes.KOO}`}
              element={<CatalogsPage type={CatalogTypes.KOO} />}
            />
            <Route
              path={`catalogs/${CatalogTypes.KOO}/:equipmentId`}
              element={<CatalogEquipmentEditorPage />}
            />
            <Route
              path={`catalogs/${CatalogTypes.SIM}`}
              element={<CatalogsPage type={CatalogTypes.SIM} />}
            />
            <Route
              path={`catalogs/${CatalogTypes.PU}`}
              element={<CatalogsPage type={CatalogTypes.PU} />}
            />
            <Route
              path={`catalogs/${CatalogTypes.PU}/:puId`}
              element={<CatalogPuEditorPage />}
            />
            <Route
              path={`tasks/ppo-map`}
              element={<PpoMapPage />}
            />
            <Route
              path={`registry/browse`}
              element={<RegistryBrowsePage />}
            />
            <Route
              path={`registry/enforse`}
              element={<RegistryEnforsePage />}
            />
            <Route
              path='registry/browse/:id'
              element={<RegistryEditorPage />}
            />
            <Route
              path='registry/mail-notifications'
              element={<MailNotificationsPage />}
            />
            <Route
              path='registry/mass-loading-ADPU'
              element={<MassLoadingADPUPage />}
            />
            <Route
              path='registry/registry-adpu'
              element={<RegistryADPUPage />}
            />
            <Route
              path={`registry/drive`}
              element={<DrivePage />}
            />
            <Route
              path={`registry/drive/:resGroupId`}
              element={<ResFoldersPage />}
            />
            <Route
              path={`registry/drive/:resGroupId/:groupId`}
              element={<FoldersPage />}
            />
            <Route
              path={`registry/drive/:resGroupId/:groupId/:folderId`}
              element={<FilesPage />}
            />
            <Route
              path='dashboard'
              element={<DashboardOutlet />}
            >
              <Route
                index
                element={<DashBoardPage />}
              />
              <Route
                path='asumb'
                element={<AsumbStatisticPage />}
              />
              <Route
                path='requests'
                element={<RequestStatisticPage />}
              />
              <Route
                path='interaction'
                element={<CustomerInteractionStatisticsPage />}
              />
              <Route
                path='odpu'
                element={<OdpuStatisticPage />}
              />
              <Route
                path='investProgram'
                element={<InvestStatisticPage />}
              />
            </Route>
            <Route
              path='/mcRework'
              element={<McReworkPage />}
            />
            <Route
              path='/yunga'
              element={<YungaPage />}
            />
            <Route
              path='/roles'
              element={<RolesPage />}
            />

            <Route
              path='/gallery'
              element={<GalleryPage />}
            />
            <Route
              path='/complaints'
              element={<ComplaintsPage />}
            />
          </Route>
        </Routes>
      </div>
    </RoleProvider>
  );
};
