import { ApiResponse } from '@/types/api';
import { HOST, YodaRestClient } from '@/services/YodaAuthService';
import {
  HouseCommentCreateRequestDto,
  HouseDataFilterParam,
} from './dto/HouseDataServiceRequestDto';
import {
  HouseDataBrowseDto,
  HouseDataDto,
  HouseDataOdpuReplacement,
  HouseAdressesChangesDto,
  HouseRelevantTaskDto,
  HouseCommentResponseDto,
} from './dto/HouseDataServiceResponseDto';

export async function ApiFindHouseBrowseData(
  filter: HouseDataFilterParam
): ApiResponse<HouseDataBrowseDto[]> {
  const url = HOST + `/rest/house/find-by-filter`;
  return YodaRestClient.post(url, filter);
}

export async function ApiFindHouseData(id: string): ApiResponse<HouseDataDto> {
  const url = HOST + `/rest/house/${id}`;
  return YodaRestClient.get(url);
}

export async function ApiUpdateHouseData(
  houseId: string,
  floorCount: number,
  entranceCount: number,
  flatCount: number,
  address?: string,
  puConnectType?: string | null,
  odpuManagementCompanyId?: number | null
): ApiResponse<HouseDataDto> {
  const url = HOST + `/rest/house/`;
  return YodaRestClient.put(url, {
    houseId,
    floorCount,
    entranceCount,
    flatCount,
    address,
    puConnectType,
    odpuManagementCompanyId,
  });
}

export async function ApiCountOfHouseBrowseData(filter: HouseDataFilterParam): ApiResponse<number> {
  const url = HOST + `/rest/house/count`;
  return YodaRestClient.post(url, filter);
}

export async function ApiFindHouseOdpuReplacement(
  id?: string
): ApiResponse<HouseDataOdpuReplacement[]> {
  const url = HOST + `/rest/house/${id}/odpu/replacement`;
  return YodaRestClient.get(url);
}

export async function ApiFindHouseRelevantTaskDtos(
  id: string,
  currentYear?: boolean
): ApiResponse<HouseRelevantTaskDto[]> {
  const url = HOST + `/rest/house/${id}/relevant?isCurrentYear=${currentYear ? 'true' : 'false'}`;
  return YodaRestClient.get(url);
}

export async function ApiFindHouseAddressChanges(id: string): ApiResponse<HouseAdressesChangesDto> {
  const url = HOST + `/rest/house/address/${id}/addresses`;
  return YodaRestClient.get(url);
}

export async function ApiFindHouseOdpuReplacementCount(id?: string): ApiResponse<number> {
  const url = HOST + `/rest/house/${id}/odpu/replacement/count`;
  return YodaRestClient.get(url);
}

export async function ApiFindHouseComments(
  houseId: string
): ApiResponse<HouseCommentResponseDto[]> {
  const url = `/rest/house/${houseId}/comment`;
  return YodaRestClient.get(url);
}

export async function ApiDeleteHouseComment(
  houseId: string,
  commentId: string
): ApiResponse<unknown> {
  const url = `/rest/house/${houseId}/comment/${commentId}`;
  return YodaRestClient.delete(url);
}

export async function ApiCreatedHouseComment(
  houseId: string,
  comment: HouseCommentCreateRequestDto
): ApiResponse<HouseCommentResponseDto> {
  const url = `/rest/house/${houseId}/comment`;
  return YodaRestClient.post(url, comment);
}
