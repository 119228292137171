import React, { useEffect, useMemo, useState } from 'react';
import { SupplyPUVirtualizedTableProps } from './SupplyPUVirtualizedTable';
import {
  SupplyPUTableCell,
  SupplyPUComponentTableRowWrapper,
  SupplyPUTableInputCell,
  SupplyPUTableInput,
  SupplyPURowButton,
  SupplyPUTableCommentCell,
  SupplyPUCommentTextField,
} from './SupplyPUComponent.styled';
import {
  convertIntoFilter,
  DOUBLE_NUMBER_METER_TYPE_CELL_DATA_ATTRIBUTE,
  DOUBLE_NUMBER_METER_TYPE_SPLITTER,
  focusOnCell,
  isChildType,
  PU_SCAN_FIELD,
  splitDoubleNumber,
  SupplyPUItemConfirmState,
  useAdminAccess,
  useStorehouseWorkerAccess,
} from './utils';
import SupplyReturnBillExtraColumns from '../SupplyReturnBillExtraColumns/SupplyReturnBillExtraColumns';
import { playAudioByKey } from '@/utils/heplers';
import shallow from 'zustand/shallow';
import useSupplyActiveRow from '../SupplyActiveRow/store';
import { EquipmentTypes } from '../utils';
import { Autocomplete, TextField } from '@mui/material';
import { SelectFilterOptType } from '@/components/filter/MultySelectFilter';
import useSupplyStore from '../SupplyStore';
import { SupplyPU } from '@/dto/taskmap/Dto';
import { useAllPuTypes } from '@/hooks/useQuery/useAllPuTypes';
import { SupplyCommentModal } from '../SupplyCommentModal/SupplyCommentModal';
import { Popper } from '@/components/fixUI/Popper';
import {
  EMPTY_RETURN_NEW_ITEM_CELLS,
  EMPTY_TWO_CELLS,
} from '../SupplyReturnBillExtraColumns/utils';

export interface SupplyPUTableProps {
  index: number;
  style: React.CSSProperties;
  data: Omit<SupplyPUVirtualizedTableProps, 'confirmScanedNumber' | 'setPUListItems'>;
}

const SupplyPURow = ({
  index,
  style,
  data: {
    puListItems,
    removeScanRow,
    handleSave,
    onEditStart,
    disabledOrDraftCheck,
    selectedPU,
    osDupFieldError,
    noYodaTaskFieldError,
    osExistsFieldError,
    returnBill,
    showMessage,
    confirmScanRow,
    viewMode,
    billWithConfirm,
    relocationGetBill,
    tabType,
    accessProject,
    checkPuItemExists,
    loadingCheckExist,
  },
}: SupplyPUTableProps) => {
  const workerAccess = useStorehouseWorkerAccess();
  const adminAccess = useAdminAccess();
  const { setActiveRowIndex, activeRowIndex } = useSupplyActiveRow(
    (state) => ({
      setActiveRowIndex: state.setActiveRowIndex,
      activeRowIndex: state.activeRowIndex,
    }),
    shallow
  );

  const { puTypesResponse, isLoading } = useAllPuTypes();
  const row = puListItems[index];

  const meterTypes = convertIntoFilter(puTypesResponse);
  const meterTypeItem = row.meterTypeId
    ? meterTypes.find((option) => option.value === row.meterTypeId)
    : null;

  const meterTypeScanField =
    selectedPU?.meterTypeScanField !== '' ? selectedPU?.meterTypeScanField : 'NUMBER';
  const puScanField = meterTypeScanField?.toLowerCase() as PU_SCAN_FIELD;

  const [meterType, setMeterType] = useState<SelectFilterOptType | null>(meterTypeItem || null);

  const rowIsRim = puTypesResponse?.find((pu) => pu.id === row.meterTypeId)?.isRim;

  const [macNumber, setMacNumber] = useState(() => {
    let value = row[puScanField];
    if (selectedPU?.isRim || rowIsRim) {
      const { number1 } = splitDoubleNumber(value);
      value = number1;
    }
    return value || '';
  });

  const [macNumber2, setMacNumber2] = useState(() => {
    let value = row[puScanField];
    if (selectedPU?.isRim || rowIsRim) {
      const { number2 } = splitDoubleNumber(value);
      value = number2;
    }
    return value || '';
  });

  const [fullName, setFullName] = useState(() => {
    const fullNamePu = puTypesResponse?.find((pu) => pu.id === meterTypeItem?.value)?.fullName;
    return {
      text: fullNamePu || '',
      prevValue: '',
    };
  });

  const [selectedChildType, setSelectedChildType] = useState(null);
  const returnNewItemsMode = useSupplyStore((state) => state.returnNewItemsMode);

  const error =
    osDupFieldError?.get(selectedPU?.tmpId ?? -1)?.get(row.tmpId) ||
    osExistsFieldError?.get(selectedPU?.tmpId ?? -1)?.get(row.tmpId) ||
    noYodaTaskFieldError?.get(selectedPU?.tmpId ?? -1)?.get(row.tmpId);

  const handleBlur = ({ withSound }: { withSound?: boolean }) => {
    let puValue = '';
    if ((selectedPU?.isRim || rowIsRim) && macNumber.length && macNumber2.length) {
      puValue = `${macNumber}${DOUBLE_NUMBER_METER_TYPE_SPLITTER}${macNumber2}`;
    }

    if ((selectedPU?.isRim || rowIsRim) && returnBill) {
      puValue = `${macNumber}${DOUBLE_NUMBER_METER_TYPE_SPLITTER}${macNumber2}`;
    }
    if (!(selectedPU?.isRim || rowIsRim)) {
      puValue = macNumber;
    }
    handleSave({
      ...row,
      [puScanField]: puValue,
    });

    if (
      row?.meterTypeId > 0 &&
      ((row.mac && row.mac?.length > 0) || (row.number && row.number?.length > 0))
    ) {
      checkPuItemExists(row);
    }

    if (withSound) {
      playAudioByKey('attention');
    }
  };

  useEffect(() => {
    const fullNamePu = puTypesResponse?.find((pu) => pu.id === meterType?.value)?.fullName;
    handleSave({
      ...row,
      meterTypeName: meterType?.label ?? '',
      meterTypeId: meterType?.value ?? null,
      fullName: fullNamePu,
    });

    if (!fullNamePu) {
      return;
    }

    setFullName((prev) => ({ ...prev, text: fullNamePu }));
  }, [meterType]);

  const handleFocus = () => onEditStart({ ...row });
  const handleDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, rowId: number) => {
    e.stopPropagation();
    row?.tmpId && removeScanRow(rowId);
    setActiveRowIndex(null);
  };
  const handleConfirm = (rowId: number) => row?.tmpId && confirmScanRow(rowId);

  const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      const selectNextRow = () => {
        const nextRowCell = puListItems[index + 1]?.tmpId;
        nextRowCell && focusOnCell({ cellRowId: nextRowCell });
      };
      if (selectedPU?.isRim || rowIsRim) {
        const cellDoubleNumberStage =
          e.currentTarget.dataset[DOUBLE_NUMBER_METER_TYPE_CELL_DATA_ATTRIBUTE]!;
        if (cellDoubleNumberStage === '1') {
          const currentRowId = puListItems[index]?.tmpId;
          currentRowId && focusOnCell({ cellRowId: currentRowId, secondNumber: true });
        } else {
          selectNextRow();
        }
      } else {
        selectNextRow();
      }
    }
  };

  const disableWorkerAccess = workerAccess && returnBill && !adminAccess;
  const disableNumberInput =
    disabledOrDraftCheck || disableWorkerAccess || relocationGetBill || loadingCheckExist;
  const handleActiveRow = () => {
    if (tabType === 'return') {
      setActiveRowIndex(row?.tmpId);
    }
  };

  const childTypeOptions = useMemo(
    () => meterTypes.filter((pu) => isChildType(pu.label)),
    [meterTypes]
  );

  const [activeModal, setActiveModal] = useState(false);

  const handleEnterComment = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') handleCommentSave();
  };

  const handleCommentSave = () => setActiveModal(false);

  return (
    <>
      <div
        style={style}
        onClick={handleActiveRow}
      >
        <SupplyPUComponentTableRowWrapper
          error={!!error}
          unSavedItem={!row.backendItem}
          className='SupplyVirtualizedTable_Row'
          active={activeRowIndex === row?.tmpId}
        >
          <SupplyPUTableCell
            withoutPadding
            narrow
          >
            {row.rowNum}
          </SupplyPUTableCell>
          {returnNewItemsMode && row.rowNum !== 1 ? (
            <>
              {EMPTY_TWO_CELLS.map((cell, index) => (
                <SupplyPUTableCell key={index} />
              ))}
            </>
          ) : (
            <>
              {returnBill ? (
                <SupplyPUTableCell>
                  <Autocomplete
                    fullWidth
                    disabled={disabledOrDraftCheck && !accessProject}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    PopperComponent={Popper}
                    options={meterTypes}
                    value={meterType}
                    onChange={(_event: any, newValue: any) => setMeterType(newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={!meterType}
                        variant='standard'
                      />
                    )}
                  />
                </SupplyPUTableCell>
              ) : (
                <SupplyPUTableCell>{selectedPU?.meterName}</SupplyPUTableCell>
              )}
              {returnBill && (
                <>
                  {meterType && !isChildType(meterType.label) ? (
                    <SupplyPUTableCell>
                      <Autocomplete
                        fullWidth
                        disabled={disabledOrDraftCheck && !accessProject}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        PopperComponent={Popper}
                        options={childTypeOptions}
                        value={selectedChildType}
                        onChange={(_event: any, newValue: any) => setMeterType(newValue)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant='standard'
                            error={!selectedChildType}
                          />
                        )}
                      />
                    </SupplyPUTableCell>
                  ) : (
                    <SupplyPUTableCommentCell
                      onClick={() => {
                        setFullName((prev) => ({ ...prev, prevValue: prev.text }));
                        setActiveModal(true);
                      }}
                      disabled={disabledOrDraftCheck && !accessProject}
                    >
                      <SupplyPUCommentTextField
                        variant='standard'
                        value={fullName.text}
                        error={!fullName.text.length}
                      />
                    </SupplyPUTableCommentCell>
                  )}
                </>
              )}
            </>
          )}
          {selectedPU?.isRim || rowIsRim ? (
            <>
              <SupplyPUTableInputCell
                narrow={returnBill}
                confirmed={
                  !error &&
                  (row.confirmed === SupplyPUItemConfirmState.firstNumber ||
                    row.confirmed === SupplyPUItemConfirmState.bothNumbers)
                }
                onBlur={() => handleBlur({ withSound: false })}
              >
                <SupplyPUTableInput
                  type='text'
                  name='mac/number'
                  value={macNumber}
                  onChange={(e) => setMacNumber(e.target.value)}
                  onFocus={handleFocus}
                  onKeyDown={handleEnter}
                  autoComplete='off'
                  data-tmpid={row.tmpId}
                  data-double-number='1'
                  disabled={disableNumberInput}
                />
              </SupplyPUTableInputCell>
              <SupplyPUTableInputCell
                narrow={returnBill}
                onBlur={() => handleBlur({ withSound: true })}
                confirmed={
                  !error &&
                  (row.confirmed === SupplyPUItemConfirmState.secondNumber ||
                    row.confirmed === SupplyPUItemConfirmState.bothNumbers)
                }
              >
                <SupplyPUTableInput
                  type='text'
                  name='mac/number'
                  value={macNumber2}
                  onChange={(e) => setMacNumber2(e.target.value)}
                  onFocus={handleFocus}
                  onKeyDown={handleEnter}
                  autoComplete='off'
                  data-tmpid={row.tmpId}
                  data-double-number='2'
                  disabled={disableNumberInput}
                />
              </SupplyPUTableInputCell>
            </>
          ) : (
            <SupplyPUTableInputCell
              onBlur={() => handleBlur({ withSound: false })}
              confirmed={
                !error &&
                (row.confirmed === SupplyPUItemConfirmState.firstNumber ||
                  row.confirmed === SupplyPUItemConfirmState.bothNumbers)
              }
            >
              <SupplyPUTableInput
                type='text'
                name='mac/number'
                value={macNumber}
                onChange={(e) => setMacNumber(e.target.value)}
                onFocus={handleFocus}
                onKeyDown={handleEnter}
                autoComplete='off'
                data-tmpid={row.tmpId}
                disabled={disableNumberInput}
              />
            </SupplyPUTableInputCell>
          )}
          {returnBill && (
            <SupplyReturnBillExtraColumns
              handleSave={handleSave}
              row={row}
              disabled={disabledOrDraftCheck && !accessProject}
              showMessage={showMessage}
              type={EquipmentTypes.PU}
              selectedEquipment={selectedPU}
            />
          )}

          {!viewMode && (
            <>
              {tabType !== 'relocation_get' && (
                <SupplyPUTableCell>
                  <SupplyPURowButton
                    disabled={disabledOrDraftCheck}
                    onClick={(e) => {
                      handleDelete(e, row.tmpId);
                    }}
                  >
                    Удалить
                  </SupplyPURowButton>
                </SupplyPUTableCell>
              )}

              {billWithConfirm && workerAccess && (
                <SupplyPUTableCell>
                  <SupplyPURowButton
                    disabled={disabledOrDraftCheck && !relocationGetBill}
                    onClick={() => handleConfirm(row.tmpId)}
                    errorBorder={
                      selectedPU?.isRim || rowIsRim
                        ? row.confirmed !== SupplyPUItemConfirmState.bothNumbers
                        : !row.confirmed
                    }
                  >
                    Подтвердить
                  </SupplyPURowButton>
                </SupplyPUTableCell>
              )}
            </>
          )}
        </SupplyPUComponentTableRowWrapper>
      </div>
      <SupplyCommentModal
        isOpen={activeModal}
        onCloseHandler={handleCommentSave}
        title={'Полное наименование'}
        value={fullName.text}
        onChangeHandler={(e) => setFullName((prev) => ({ ...prev, text: e.target.value }))}
        error={!fullName.text.length}
        disabled={meterType !== null}
        onKeyDownHandler={handleEnterComment}
        saveHandler={handleCommentSave}
      />
    </>
  );
};

export default SupplyPURow;
