import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, CircularProgress } from '@mui/material';

import { download, downloadPresent, downloadTKO, downloadVRU } from '@/services/ReportService';
import { useCatalog } from '@/hooks/CatalogHook';
import { useActionLog } from '@/hooks/ActionLogHook';

import { IPopup, Popup } from '../Popup';
import { AutocompleteValue } from '../../inputs/AutocompleteValue/AutocompleteValue';
import styles from '../Popup.module.scss';
import { DownloadPopupBackdrop } from './DownloadPopup.styled';
import { AxiosResponse } from 'axios';

interface Props extends IPopup {
  id: string;
  type: DownloadType;
  objId?: string | null;
}

export enum DownloadType {
  TKO,
  ADPU,
  ADPU_VRU,
  ADPU_PRESENT,
}
interface DownloadConfigItem {
  fetchData: () => void;
  getValues: () => string[];
  downloadReport: (data: any) => Promise<any>;
}

// Универсальная функция для скачивания
const downloadFile = (res: AxiosResponse<any>, fileName: string, mimeType: string) => {
  const file = new Blob([res.data], { type: mimeType });
  const fileUrl = URL.createObjectURL(file);
  const a = document.createElement('a');
  a.href = fileUrl;
  a.download = fileName;
  a.click();
};

// Функция для скачивания PDF-отчёта, использующая downloadFile
const downloadResFile = (res: AxiosResponse<any>) => {
  downloadFile(res, 'отчет.pdf', 'application/pdf');
};

export const DownloadPopup: React.FC<Props> = (props) => {
  const { fetchCatch } = useActionLog();
  const [loading, setLoading] = useState<boolean>(false);
  const {
    adpuArray,
    getAdpuArray,
    tkoArray,
    getTKOArray,
    adpuVruArray,
    getAdpuVruArray,
    adpuPresentArray,
    getAdpuPresentArray,
  } = useCatalog((state) => ({
    adpuArray: state.adpuArray,
    getAdpuArray: state.getAdpuArray,
    getTKOArray: state.getTKOArray,
    tkoArray: state.tkoArray,
    adpuVruArray: state.adpuVruArray,
    getAdpuVruArray: state.getAdpuVruArray,
    adpuPresentArray: state.adpuPresentArray,
    getAdpuPresentArray: state.getAdpuPresentArray,
  }));
  const { onClose, type } = props;
  const methods = useForm();
  const handleSubmitTKO = (data: any) => {
    setLoading(true);
    const code = tkoArray.find((el) => el?.name === data?.shablon)?.code ?? '';
    const fileName = data?.shablon.replace(/_[^_]*комментар[^_]*/g, '');
    return downloadTKO(code, props.id).then((res) => {
      return downloadFile(
        res,
        `${fileName}.docx`,
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      );
    });
  };

  const handleOnFinally = () => {
    setLoading(false);
    onClose();
  };

  const downloadConfig: Record<DownloadType, DownloadConfigItem> = {
    [DownloadType.ADPU]: {
      fetchData: getAdpuArray,
      getValues: () => adpuArray,
      downloadReport: (data) => download(data.shablon, props.id).then(downloadResFile),
    },
    [DownloadType.ADPU_VRU]: {
      fetchData: getAdpuVruArray,
      getValues: () => adpuVruArray,
      downloadReport: (data) =>
        downloadVRU(data.shablon, props.id, props.objId).then(downloadResFile),
    },
    [DownloadType.ADPU_PRESENT]: {
      fetchData: getAdpuPresentArray,
      getValues: () => adpuPresentArray,
      downloadReport: (data) => downloadPresent(data.shablon, props.id).then(downloadResFile),
    },
    [DownloadType.TKO]: {
      fetchData: getTKOArray,
      getValues: () => tkoArray.map((el) => el?.name),
      downloadReport: (data) => handleSubmitTKO(data),
    },
  };

  useEffect(() => {
    downloadConfig[type]?.fetchData();
  }, [props.isOpen, type]);

  const values = useMemo(() => downloadConfig[type]?.getValues() || [], [type, props.isOpen]);

  const handleSubmitMain = (data: any) => {
    if (props.id === '') {
      fetchCatch({}, 'Не найдена информация о ПУ');
      return;
    }

    downloadConfig[type]?.downloadReport(data).catch(fetchCatch).finally(handleOnFinally);
  };

  return (
    <Popup {...props}>
      <h3>Выберите шаблон отчета из списка</h3>
      <div>
        <FormProvider {...methods}>
          <AutocompleteValue
            title='Шаблоны'
            fieldName={`shablon`}
            values={values}
          />
        </FormProvider>
      </div>
      <div className={styles['modal-buttons']}>
        <DownloadPopupBackdrop open={loading}>
          <CircularProgress color='inherit' />
        </DownloadPopupBackdrop>
        <Button
          onClick={methods.handleSubmit(handleSubmitMain)}
          variant='contained'
        >
          Да
        </Button>
        <Button
          onClick={onClose}
          variant='contained'
        >
          Нет
        </Button>
      </div>
    </Popup>
  );
};
